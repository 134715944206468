import { useRef, useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useNavigate } from "react-router-dom";
import logoBig from "./falsotep-big.svg";
import logoMantello from "./logo.svg";
import background from "./retro.png";
import button from "./button.svg";
import { isMobile } from "react-device-detect";

export default function Intro() {
  const [activeSection, setActiveSection] = useState(0);
  let navigate = useNavigate();

  useEffect(() => {
    controls.start({
      opacity: 0,
    });

    controls2.start({
      opacity: 1,
    });
  }, [activeSection]);

  const controls = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  return (
    <div>
      <div
        style={{
          position: "absolute",
          backgroundColor: "#000",

          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
        }}
        className="flex justify-center items-center"
      >
        {activeSection === 0 && (
          <div
            className="bg-white rounded-3xl w-80 md:w-96 p-8"
            style={{
              height: isMobile ? "70vh" : "600px",
            }}
            animate={controls2}
            initial={{ opacity: 0 }}
            transition={{
              duration: 1,
            }}
          >
            <motion.div
              animate={controls2}
              initial={{ opacity: 0 }}
              transition={{
                duration: 1,
              }}
              className="flex flex-col items-center p-8 border border-2 border-black rounded-3xl h-full"
            >
              <img src={logoMantello} style={{ width: "60%" }} />
              <img src={logoBig} className="mt-8 w-48" />
              <div className="font-sans text-xs mt-8 w-full text-center">
                Un’opera partecipata di Simona Anna Gentile con gli abitanti del
                quartiere Colognola a cura di Giovanni Berera e Arianna Lenzi
              </div>
              <motion.div
                className="relative flex justify-center items-center text-white w-full cursor-pointer"
                onClick={() => {
                  setActiveSection(1);
                  setTimeout(() => {
                    controls3.start({
                      opacity: 1,
                    });
                  }, 1000);
                }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 1.1 }}
                transition={{
                  duration: 1,
                }}
              >
                <img
                  src={button}
                  className="absolute w-48 inset-0 left-0 right-0 mx-auto top-4 z-0"
                />
                <div className="absolute w-full left-0 right-0 mx-auto top-8 z-0 text-3xl text-center">
                  INIZIA
                </div>
              </motion.div>
            </motion.div>
          </div>
        )}
        {activeSection === 1 && (
          <div
            className="bg-white rounded-3xl w-80 md:w-96 p-8"
            style={{
              height: isMobile ? "70vh" : "600px",
            }}
          >
            <div className="flex p-2 md:p-8 border border-2 border-black rounded-3xl h-full">
              <motion.div
                className="flex flex-col items-center"
                animate={controls3}
                initial={{ opacity: 0 }}
                transition={{
                  duration: 1,
                }}
              >
                <div className="font-sans text-sm md:text-base w-full text-center pt-8 md:pt-0">
                  “Fà ‘l sò tép’’ è l'installazione artistica realizzata
                  dall’artista Simona Anna Gentile insieme agli abitanti del
                  quartiere di Colognola ed è frutto di un progetto d’arte
                  partecipata che si è svolto a Colognola tra febbraio e giugno
                  2022.
                  <br /> <br /> L’opera si ispira all’Arazzo di Bayeux,
                  capolavoro d’arte tessile medievale dedicato alle gesta di
                  Guglielmo il Conquistatore, e racconta, su 70 metri di stoffa
                  cucita e dipinta, la vita quotidiana del quartiere e dei suoi
                  abitanti, la loro memoria e il loro futuro.
                </div>
                <motion.div
                  className="relative flex justify-center items-center text-white w-full cursor-pointer"
                  onClick={() => {
                    controls.start({
                      opacity: 1,
                    });
                    setTimeout(() => {
                      navigate("/home");
                    }, 2500);
                  }}
                  whileTap={{ scale: 1.1 }}
                  transition={{
                    duration: 1,
                  }}
                >
                  <img
                    src={button}
                    className="absolute w-48 inset-0 left-0 right-0 mx-auto top-4 z-0"
                  />
                  <div className="absolute w-full left-0 right-0 mx-auto top-8 z-0 text-3xl text-center">
                    AVANTI
                  </div>
                </motion.div>
              </motion.div>
            </div>
          </div>
        )}
      </div>

      <motion.div
        style={{
          position: "absolute",
          backgroundColor: "#000",
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 99999999,
          pointerEvents: "none",
        }}
        animate={controls}
        initial={{ opacity: 1 }}
        transition={{
          duration: 3,
        }}
      ></motion.div>
    </div>
  );
}
