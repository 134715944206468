import { useRef, useState, useEffect, Suspense } from "react";
import background from "./background.jpg";
import { motion, useAnimation, useMotionValue } from "framer-motion";
import { isMobile } from "react-device-detect";
import Controllers from "./Controllers";
import logo from "./falsotep.svg";
import card from "./retro-carta.jpg";
import close from "./close.svg";
import playSvg from "./play.svg";
import pauseSvg from "./pause.svg";
import audio10 from "./10.mp3";
import audio9 from "./9.mp3";
import audio8 from "./8.mp3";
import audio7 from "./7.mp3";
import audio6 from "./6.mp3";
import audio5 from "./5.mp3";
import audio4 from "./4.mp3";
import audio3 from "./3.mp3";
import audio2 from "./2.mp3";
import audio1 from "./1.mp3";
import audio41 from "./41.mp3";
import audio42 from "./42.mp3";
import audio40 from "./40.mp3";
import audio39 from "./39.mp3";
import audio38 from "./38.mp3";
import audio37 from "./37.mp3";
import audio36 from "./36.mp3";
import audio35 from "./35.mp3";
import audio34 from "./34.mp3";
import audio33 from "./33.mp3";
import audio32 from "./32.mp3";
import audio31 from "./31.mp3";
import audio20 from "./20.mp3";
import audio19 from "./19.mp3";
import audio18 from "./18.mp3";
import audio17 from "./17.mp3";
import audio16 from "./16.mp3";
import audio15 from "./15.mp3";
import audio14 from "./14.mp3";
import audio13 from "./13.mp3";
import audio12 from "./12.mp3";
import audio11 from "./11.mp3";
import audio30 from "./30.mp3";
import audio29 from "./29.mp3";
import audio28 from "./28.mp3";
import audio27 from "./27.mp3";
import audio26 from "./26.mp3";
import audio25 from "./25.mp3";
import audio24 from "./24.mp3";
import audio23 from "./23.mp3";
import audio22 from "./22.mp3";
import audio21 from "./21.mp3";

import image10 from "./10.jpg";
import image9 from "./9.jpg";
import image8 from "./8.jpg";
import image7 from "./7.jpg";
import image6 from "./6.jpg";
import image5 from "./5.jpg";
import image4 from "./4.jpg";
import image3 from "./3.jpg";
import image2 from "./2.jpg";
import image1 from "./1.jpg";
import image41 from "./41.jpg";
import image42 from "./42.jpg";
import image40 from "./40.jpg";
import image39 from "./39.jpg";
import image38 from "./38.jpg";
import image37 from "./37.jpg";
import image36 from "./36.jpg";
import image35 from "./35.jpg";
import image34 from "./34.jpg";
import image33 from "./33.jpg";
import image32 from "./32.jpg";
import image31 from "./31.jpg";
import image20 from "./20.jpg";
import image19 from "./19.jpg";
import image18 from "./18.jpg";
import image17 from "./17.jpg";
import image16 from "./16.jpg";
import image15 from "./15.jpg";
import image14 from "./14.jpg";
import image13 from "./13.jpg";
import image12 from "./12.jpg";
import image11 from "./11.jpg";
import image30 from "./30.jpg";
import image29 from "./29.jpg";
import image28 from "./28.jpg";
import image27 from "./27.jpg";
import image26 from "./26.jpg";
import image25 from "./25.jpg";
import image24 from "./24.jpg";
import image23 from "./23.jpg";
import image22 from "./22.jpg";
import image21 from "./21.jpg";
import { titles } from "./utils";

export default function App() {
  const [activeSection, setActiveSection] = useState("selection");
  const [active, setActive] = useState(false);
  const [id, setId] = useState(null);
  const [fileAudio, setFileAudio] = useState(null);
  const [fileImage, setFileImage] = useState(null);
  const [soundActive, setSoundActive] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef();

  useEffect(() => {
    controls.start({
      opacity: 0,
      transition: {
        duration: 2,
      },
    });
  }, []);

  useEffect(() => {
    if (activeSection === "selected") {
      controls.start({
        opacity: 1,
        transition: {
          duration: 2,
        },
      });
    }
  }, [activeSection]);

  const setAudio = (id) => {
    if (id) {
      if (id === "1") {
        setFileAudio(audio1);
      } else if (id === "2") {
        setFileAudio(audio2);
      } else if (id === "3") {
        setFileAudio(audio3);
      } else if (id === "4") {
        setFileAudio(audio4);
      } else if (id === "5") {
        setFileAudio(audio5);
      } else if (id === "6") {
        setFileAudio(audio6);
      } else if (id === "7") {
        setFileAudio(audio7);
      } else if (id === "8") {
        setFileAudio(audio8);
      } else if (id === "9") {
        setFileAudio(audio9);
      } else if (id === "10") {
        setFileAudio(audio10);
      } else if (id === "11") {
        setFileAudio(audio11);
      } else if (id === "12") {
        setFileAudio(audio12);
      } else if (id === "13") {
        setFileAudio(audio13);
      } else if (id === "14") {
        setFileAudio(audio14);
      } else if (id === "15") {
        setFileAudio(audio15);
      } else if (id === "16") {
        setFileAudio(audio16);
      } else if (id === "17") {
        setFileAudio(audio17);
      } else if (id === "18") {
        setFileAudio(audio18);
      } else if (id === "19") {
        setFileAudio(audio19);
      } else if (id === "20") {
        setFileAudio(audio20);
      } else if (id === "21") {
        setFileAudio(audio21);
      } else if (id === "22") {
        setFileAudio(audio22);
      } else if (id === "23") {
        setFileAudio(audio23);
      } else if (id === "24") {
        setFileAudio(audio24);
      } else if (id === "25") {
        setFileAudio(audio25);
      } else if (id === "26") {
        setFileAudio(audio26);
      } else if (id === "27") {
        setFileAudio(audio27);
      } else if (id === "28") {
        setFileAudio(audio28);
      } else if (id === "29") {
        setFileAudio(audio29);
      } else if (id === "30") {
        setFileAudio(audio30);
      } else if (id === "31") {
        setFileAudio(audio31);
      } else if (id === "32") {
        setFileAudio(audio32);
      } else if (id === "33") {
        setFileAudio(audio33);
      } else if (id === "34") {
        setFileAudio(audio34);
      } else if (id === "35") {
        setFileAudio(audio35);
      } else if (id === "36") {
        setFileAudio(audio36);
      } else if (id === "37") {
        setFileAudio(audio37);
      } else if (id === "38") {
        setFileAudio(audio38);
      } else if (id === "39") {
        setFileAudio(audio39);
      } else if (id === "40") {
        setFileAudio(audio40);
      } else if (id === "41") {
        setFileAudio(audio41);
      } else if (id === "42") {
        setFileAudio(audio42);
      }
    }
  };

  const setImage = (id) => {
    if (id) {
      if (id === "1") {
        setFileImage(image1);
      } else if (id === "2") {
        setFileImage(image2);
      } else if (id === "3") {
        setFileImage(image3);
      } else if (id === "4") {
        setFileImage(image4);
      } else if (id === "5") {
        setFileImage(image5);
      } else if (id === "6") {
        setFileImage(image6);
      } else if (id === "7") {
        setFileImage(image7);
      } else if (id === "8") {
        setFileImage(image8);
      } else if (id === "9") {
        setFileImage(image9);
      } else if (id === "10") {
        setFileImage(image10);
      } else if (id === "11") {
        setFileImage(image11);
      } else if (id === "12") {
        setFileImage(image12);
      } else if (id === "13") {
        setFileImage(image13);
      } else if (id === "14") {
        setFileImage(image14);
      } else if (id === "15") {
        setFileImage(image15);
      } else if (id === "16") {
        setFileImage(image16);
      } else if (id === "17") {
        setFileImage(image17);
      } else if (id === "18") {
        setFileImage(image18);
      } else if (id === "19") {
        setFileImage(image19);
      } else if (id === "20") {
        setFileImage(image20);
      } else if (id === "21") {
        setFileImage(image21);
      } else if (id === "22") {
        setFileImage(image22);
      } else if (id === "23") {
        setFileImage(image23);
      } else if (id === "24") {
        setFileImage(image24);
      } else if (id === "25") {
        setFileImage(image25);
      } else if (id === "26") {
        setFileImage(image26);
      } else if (id === "27") {
        setFileImage(image27);
      } else if (id === "28") {
        setFileImage(image28);
      } else if (id === "29") {
        setFileImage(image29);
      } else if (id === "30") {
        setFileImage(image30);
      } else if (id === "31") {
        setFileImage(image31);
      } else if (id === "32") {
        setFileImage(image32);
      } else if (id === "33") {
        setFileImage(image33);
      } else if (id === "34") {
        setFileImage(image34);
      } else if (id === "35") {
        setFileImage(image35);
      } else if (id === "36") {
        setFileImage(image36);
      } else if (id === "37") {
        setFileImage(image37);
      } else if (id === "38") {
        setFileImage(image38);
      } else if (id === "39") {
        setFileImage(image39);
      } else if (id === "40") {
        setFileImage(image40);
      } else if (id === "41") {
        setFileImage(image41);
      } else if (id === "42") {
        setFileImage(image42);
      }
    }
  };

  const controls = useAnimation();

  const toggleSound = () => {
    setSoundActive((state) => !state);
  };

  const [flipped, setFlipped] = useState(false);

  const playCallback = () => {
    if (!isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const generateId = () => {
    const randomId = Math.floor(Math.random() * 42);
    console.log("randomId", randomId + 1);
    setId(randomId + 1);
    setAudio(String(randomId + 1));
    setImage(String(randomId + 1));
  };

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
      }}
      className="w-full h-full"
    >
      <div
        className="absolute w-100 left-0 right-0 top-2 flex justify-center text-white text-2xl md:text-4xl"
        style={{
          zIndex: 9,
        }}
      >
        <img src={logo} width={isMobile ? 120 : 180} />
      </div>

      <div
        style={{
          position: "absolute",
          backgroundSize: "cover",
          left: 0,
          top: "10vh",
          right: 0,
          width: "100",
          height: "70vh",
        }}
        className="flex justify-center items-center"
      >
        <div className="w-64 md:w-[30vh] h-[50vh] md:h-[60vh] absolute z-10 rounded-3xl">
          <div className={`card ${flipped ? "flipped" : ""} rounded-3xl`}>
            <div
              className="front face rounded-3xl"
              style={{
                backgroundImage: `url(${card})`,
                backgroundSize: "cover",
                transformStyle: "preserve-3d",
              }}
            ></div>
            <div
              className="back face relative p-4 rounded-3xl"
              style={{
                backgroundColor: "#fff",
                transformStyle: "preserve-3d",
              }}
            >
              <div
                className="absolute -top-1 cursor-pointer select-none"
                onClick={() => {
                  setFlipped(false);
                  audioRef.current.pause();
                  setIsPlaying(false);
                  setFileAudio(null);
                  setFileImage(null);
                }}
                style={{
                  WebkitTapHighlightColor: "transparent",
                }}
              >
                <motion.img src={close} className="select-none" />
              </div>
              <div className="flex flex-col items-center justify-center p-0 overflow-hidden border border-2 border-black rounded-3xl h-full">
                <div
                  className="w-full h-full"
                  style={{
                    backgroundImage: `url(${fileImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  {/* <motion.img
                    src={fileImage}
                    //width={isMobile ? 45 : 45}
                    // style={{
                    //   opacity: soundActive ? 1 : 0.5,
                    // }}
                    className="w-full"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 1,
                    }}
                  /> */}
                </div>
              </div>
              <motion.button
                style={{
                  backgroundColor: "#000",
                  border: 0,
                  padding: "8px ",
                  //boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.7)",
                }}
                className="w-20 h-20 rounded-full text-white text-2xl flex items-center justify-center absolute -bottom-8 left-0 right-0 mx-auto cursor-pointer z-20"
                onClick={() => {
                  playCallback();
                }}
                whileHover={{ backgroundColor: "#EBAE40" }}
                whileTap={{ backgroundColor: "#EBAE40" }}
              >
                <img
                  src={isPlaying ? pauseSvg : playSvg}
                  width={isMobile ? 45 : 45}
                  style={{
                    opacity: soundActive ? 1 : 0.5,
                  }}
                />
              </motion.button>
              <div className="font-sans mt-2 text-white text-center text-xs absolute -bottom-16 left-0 right-0 mx-auto">
                Ascolta il racconto
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-[28vh] md:w-[30vh] h-[50vh] md:h-[60vh] rounded-3xl absolute"
          style={{
            transform: "rotateZ(15deg)",
          }}
        >
          <div className={`card rounded-3xl`}>
            <div
              className="front face rounded-3xl"
              style={{
                backgroundImage: `url(${card})`,
                backgroundSize: "cover",
                transformStyle: "preserve-3d",
              }}
            ></div>
          </div>
        </div>
        <div
          className="w-[28vh] md:w-[30vh] h-[50vh] md:h-[60vh] rounded-3xl absolute"
          style={{
            transform: "rotateZ(4deg)",
          }}
        >
          <div className={`card rounded-3xl`}>
            <div
              className="front face rounded-3xl"
              style={{
                backgroundImage: `url(${card})`,
                backgroundSize: "cover",
                transformStyle: "preserve-3d",
              }}
            ></div>
          </div>
        </div>
        <div
          className="w-[28vh] md:w-[30vh] h-[50vh] md:h-[60vh] rounded-3xl absolute"
          style={{
            transform: "rotateZ(-8deg)",
          }}
        >
          <div className={`card rounded-3xl`}>
            <div
              className="front face rounded-3xl"
              style={{
                backgroundImage: `url(${card})`,
                backgroundSize: "cover",
                transformStyle: "preserve-3d",
              }}
            ></div>
          </div>
        </div>
        <div
          className="w-[28vh] md:w-[30vh] h-[50vh] md:h-[60vh] rounded-3xl absolute"
          style={{
            transform: "rotateZ(-5deg)",
          }}
        >
          <div className={`card rounded-3xl`}>
            <div
              className="front face rounded-3xl"
              style={{
                backgroundImage: `url(${card})`,
                backgroundSize: "cover",
                transformStyle: "preserve-3d",
              }}
            ></div>
          </div>
        </div>
      </div>

      {fileAudio && (
        <audio ref={audioRef}>
          <source src={`${fileAudio}`} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}

      <motion.div
        style={{
          position: "absolute",
          backgroundColor: "#000",
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 999,
          pointerEvents: "none",
        }}
        animate={controls}
        initial={{ opacity: 1 }}
        transition={{
          duration: 1,
          ease: "easeInOut",
        }}
      ></motion.div>

      <Controllers
        active={!flipped}
        clickCallback={() => {
          setActive(!active);
          setFlipped(!flipped);
          generateId();
        }}
        toggleSound={toggleSound}
        soundActive={soundActive}
      />
    </div>
  );
}
