import { useEffect, useState } from "react";
import { motion, useAnimation, useMotionValue } from "framer-motion";
import { isMobile } from "react-device-detect";
import button from "./button.svg";
import closeSvg from "./closewhite.svg";
import logo from "./logowhite.svg";
import logoMantelloSvg from "./logomantello.svg";
import aboutSvg from "./about.svg";

const Controllers = ({ active, clickCallback, toggleSound, soundActive }) => {
  const [aboutOpen, setAboutOpen] = useState(false);

  useEffect(() => {
    controls.start({
      opacity: active ? 1 : 0,
      transition: {
        duration: 0.5,
      },
    });
  }, [active]);

  const controls = useAnimation();

  return (
    <>
      <div
        className="absolute z-10 bottom-4 md:bottom-0 w-48 md:w-72 bg-none rounded-full px-6 md:px-8 left-0 right-0 mx-auto flex items-center justify-center"
        style={{
          height: "180px",
          pointerEvents: active ? "all" : "none",
        }}
      >
        <motion.div
          className="flex flex-col items-center"
          animate={controls}
          initial={{ opacity: active ? 1 : 0 }}
        >
          <motion.button
            style={{
              backgroundImage: `url(${button})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              color: "#fff",
              padding: "8px ",
            }}
            className="w-60 text-white text-2xl h-24"
            onClick={() => {
              if (clickCallback) {
                clickCallback();
              }
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 1.1 }}
          >
            PESCA UNA CARTA
          </motion.button>
          <div className="font-sans mt-2 text-white text-center">
            ogni volta una carta diversa per scoprire storie sempre nuove
          </div>
        </motion.div>
      </div>

      {aboutOpen && (
        <motion.div
          className="absolute text-white px-4 md:px-16 py-8 md:py-16 right-0 left-0 top-0 cursor-pointer "
          style={{
            backgroundColor: "#977E57",
            zIndex: aboutOpen ? 50 : 0,
          }}
          animate={controls}
          initial={{ opacity: 0 }}
        >
          <div className="max-w-3xl mx-auto min-h-screen">
            <div className="flex justify-center pb-8">
              <img src={logo} width={isMobile ? 160 : 200} />
            </div>
            <p className="font-sans w-ful pb-8">
              Un’opera partecipata di Simona Anna Gentile con gli abitanti del
              quartiere Colognola a cura di Giovanni Berera e Arianna Lenzi
            </p>
            <p className="font-sans w-ful border-white border-b pb-8">
              Come in pellicola cinematografica sull’arazzo di Simona Anna
              Gentile scorre la vita di Colognola. Sono 42 le scene che si
              succedono a ritmo continuato, dando forma alla trama di un film le
              cui riprese non sono ancora terminate. Citando Giuseppe Pontiggia
              che cita Cornelio Nepote, l’arazzo di Colognola, ribattezzato
              dall’artista Fà ‘l sò tép, è un film dedicato a “vite di uomini
              non illustri”, a esistenze ordinarie e uniche che hanno animato o
              che tutt’ora animano il variopinto tessuto sociale del quartiere.
              Non c’è definizione migliore per descrivere l’opera di Gentile che
              quella di “tessuto sociale”. Sulle stoffe dell’arazzo si sono
              sedimentati i racconti, le confessioni, le invettive, le memorie e
              i desideri delle donne e degli uomini che vivono a Colognola. “Fà
              ‘l sò tép’’ è l'installazione artistica realizzata dall’artista
              Simona Anna Gentile insieme agli abitanti del quartiere di
              Colognola ed è frutto di un progetto d’arte partecipata che si è
              svolto a Colognola tra febbraio e giugno 2022. L’opera si ispira
              all’Arazzo di Bayeux, capolavoro d’arte tessile medievale dedicato
              alle gesta di Guglielmo il Conquistatore, e racconta, su 70 metri
              di stoffa cucita e dipinta, la vita quotidiana del quartiere e dei
              suoi abitanti, la loro memoria e il loro futuro. L’arazzo è il
              risultato dell’assemblaggio di numerose stoffe donate dai
              residenti di Colognola. Le stoffe sono state composte dall’artista
              e cucite da un gruppo di sarte del quartiere, che con il loro
              operoso lavoro hanno dato forma complessiva all’arazzo. Il
              risultato sono 14 moduli da 5 metri ciascuno, per un totale di 70
              metri lineari. Ogni modulo raccoglie 3 dipinti maggiori che
              raccontano storie del quartiere, incorniciati da centinaia di
              piccoli disegni su stoffa realizzati dai bambini e dalle bambine
              del quartiere. I 42 dipinti maggiori sono stati realizzati su
              disegni originali di Simona Gentile. In essi l’artista gioca con i
              generi tradizionali della pittura e della narrazione, contaminando
              con forme artistiche più recenti; da un lato, i fumetti, la pop
              art e la pittura votiva, e dall’altro le leggende popolari, la
              descrizione documentaristica, il racconto autobiografico.
              L’artista genera nuove immagini, sintetizzando e fondendo
              riferimenti iconografici eterogenei. Capita così di vedere citati
              in una sola composizione una pala d’altare rinascimentale e
              l’immagine di copertina di un album di Notorius Big. È un melting
              pot figurativo che nasce dalla volontà di parlare alla vita di
              tutti e che permette a ognuno di entrare nelle storie dell’arazzo.
              Perché questa è la vera urgenza dell’artista: raggiungere un senso
              di collettività attraverso l’allenamento della memoria collettiva,
              secondo il principio per cui una sola storia può servire a
              mantenere salda la storia di tutti. Allora, una volta che l’arazzo
              sarà ostenso in tutta la sua monumentalità, sarà come chiudere un
              cerchio: tutto è nato con la richiesta di storie che avevano fatto
              il loro tempo. Tutto è finito con la convinzione che quelle storie
              stanno ancora facendo il nostro tempo.
            </p>
            <h3 className="font-sans font-bold py-8">
              PERCORSO REALIZZAZIONE OPERA
            </h3>
            <p className="font-sans w-ful border-white border-b pb-8">
              “Fà ‘l sò tép’’ è l'installazione artistica realizzata
              dall’artista Simona Anna Gentile nel quartiere di Colognola.
              L’opera prende come spunto l’Arazzo di Bayeux per raccontare,
              attraverso 70metri di stoffa cucita e dipinta, la vita quotidiana
              del quartiere, i suoi abitanti, la sua memoria storica e il suo
              futuro. L’arazzo è stato costruito con le numerose stoffe donate
              dai residenti di Colognola durante i giorni della raccolta di
              febbraio. Le stoffe sono state poi assemblate dall’artista e
              cucite da un gruppo di sarte del quartiere, che con il loro
              operoso lavoro hanno reso possibile l’assemblaggio complessivo
              dell’arazzo. Il risultato sono 14 moduli da 5 metri ognuno, per un
              totale di 70 metri complessivi; ogni modulo raccoglie 3 dipinti
              che raccontano storie estrapolate direttamente dalle interviste
              condotte nei mesi di lavoro agli abitanti del quartiere di
              Colognola. In questi disegni Simona gioca con i generi
              tradizionali della pittura e della narrazione, contaminate con le
              forme artistiche più recenti; da un lato, i fumetti, la pop art e
              la pittura votiva, e dall’altro le leggende popolari, la
              descrizione documentaristica, il racconto autobiografico.
              L’artista genera nuove immagini, sintetizzando e fondendo
              riferimenti iconografici eterogenei. Capita così di vedere citati
              in una sola composizione una pala d’altare rinascimentale e
              l’immagine di copertina di un album di Notorius Big. È un melting
              pot figurativo che nasce dalla volontà di parlare alla vita di
              tutti e che permette a ognuno di entrare nelle storie dell’arazzo.
              Perché questa è la vera urgenza dell’artista: raggiungere un senso
              di collettività attraverso l’allenamento della memoria collettiva,
              secondo il principio per cui una sola storia può servire a
              mantenere salda la storia di tutti. Allora, una volta che l’arazzo
              sarà esteso in tutta la sua monumentalità, sarà come chiudere un
              cerchio: tutto è nato dalla richiesta di storie che avevano fatto
              il loro tempo. Tutto è finito con la convinzione che quelle storie
              stanno ancora facendo il nostro tempo.
            </p>
            <h3 className="font-sans font-bold py-8">L'ARTISTA</h3>
            <p className="font-sans w-ful border-white border-b pb-8">
              Simona Anna Gentile è nata a Taranto 1993. Dopo aver conseguito il
              diploma in restauro delle opere pittoriche, si specializza nelle
              arti pittoriche presso l'Accademia di belle Arti di Lecce
              acquisendone il titolo di I e II livello. Per la formazione del
              suo linguaggio artistico sono state di notevole importanza le
              residenze d'artista: 2017, M.E.R UNI-TAN, teatri Koreja Lecce;
              2019, Sapere i Luoghi, Fondazione Giuseppe Morra (NA)/Fondazione
              Lac o le Mon (San Cesario/LE); 2020, via della fucina
              16-condominio museo, (TO). Ha partecipato alla collettiva a porte
              chiuse, esclusiva solo per gli abitanti del condominio,
              Gentilivicini, viadellafucina16 condominio museo, TO, 2021;
              Groupshow! Viadellafucina16 partecipante al NEXT di Torino durante
              l'Artissima Fair, TO, 2019; In sei atti, Fondazione Giuseppe
              Morra, NA, curata da Cesare Pietroiusti ed Emilio Fantin; mostra
              personale situazionista presso lo studio dentistico DOTT.SSA
              ROBERTA GENOVIVA, curata da Apulia artcontemporary, What can you
              find in a cavity?, TA, 2019; la bipersonale con Raffaele Fiorella,
              a cura di Eliana Masulli, Mitemi, Kunstshau contenporary art
              Place, LE, 2018 e la personale presso Hauskonzerte, TA, con A'
              piccenne, 2018.
            </p>
            <h3 className="font-sans font-bold py-8">
              {" "}
              IL MANTELLO DI ARLECCHINO
            </h3>
            <p className="font-sans w-ful border-white border-b pb-8">
              “Il Mantello di Arlecchino. Dalla circonferenza al centro”, ideato
              da TTB Teatro tascabile di Bergamo con Hg80 Impresa sociale,
              sostenuto dall’Assessorato alla Cultura, condiviso dalle Reti di
              quartiere e finanziato da Fondazione Cariplo, è un progetto che
              nasce dalla volontà di riattivare legami sociali e ricomporre,
              attraverso un'azione culturale partecipata, i micro-tessuti
              cittadini in un dialogo tra centro e periferie. Perni del progetto
              sono le pratiche legate al baratto culturale e alla partecipazione
              diretta dei cittadini. Residenti, associazioni, gruppi dei
              quartieri di Valtesse Sant’Antonio Valverde e Colognola sono stati
              chiamati a partecipare alla realizzazione di due opere d’arte
              tessile, alle quali ognuno contribuisce con una pezza di tela
              colorata; di due opere d’arte digitale ispirate alle opere visuali
              e alla raccolta di racconti, disegni e parole dei cittadini; di
              due settimane di festa durante le quali, oltre alle azioni
              spettacolari o musicali dei Festival diffusi realizzate dagli
              artisti stessi dei quartieri, verranno messe in scena, in una
              cornice spettacolare, le differenti abilità dei quartieri; e
              infine Dalla circonferenza al centro: portare il lavoro svolto nei
              e dai quartieri nei luoghi centrali della cultura.
            </p>
            <div className="flex justify-center">
              <div
                onClick={() => {
                  setTimeout(() => {
                    setAboutOpen(false);
                  }, 1000);
                }}
                className="p-6 cursor-pointer bg-white rounded-3xl h-12 my-8 text-black flex justify-center items-center text-2xl"
              >
                TORNA ALLA HOME
              </div>
            </div>
            <div className="flex justify-center  text-black">
              <a
                href="https://www.mantellodiarlecchino.it/"
                target="_blank"
                className="font-sans hover:underline"
              >
                Visita il sito del Mantello di Arlecchino
              </a>
            </div>
            <div className="flex justify-center py-8">
              <img src={logoMantelloSvg} width={isMobile ? 160 : 200} />
            </div>

            <div
              className="absolute flex flex-col items-center right-2 md:right-4 top-2 cursor-pointer z-10"
              onClick={() => {
                setTimeout(() => {
                  setAboutOpen(false);
                }, 1000);
              }}
            >
              <img
                src={closeSvg}
                width={isMobile ? 24 : 32}
                style={{
                  opacity: soundActive ? 1 : 0.5,
                }}
              />
            </div>
          </div>
        </motion.div>
      )}

      <div
        className="absolute flex flex-col items-center left-2 md:left-4 top-2 cursor-pointer z-10"
        onClick={() => {
          setAboutOpen(true);
          setTimeout(() => {
            controls.start({
              opacity: 1,
              transition: {
                duration: 1,
              },
            });
          }, 200);
        }}
      >
        <motion.img
          src={aboutSvg}
          width={isMobile ? 24 : 32}
          style={{
            opacity: 1,
          }}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 1.2 }}
        />
        <div
          className="text-black font-sans mt-2"
          style={{
            fontSize: "11px",
          }}
        >
          ABOUT
        </div>
      </div>
    </>
  );
};

export default Controllers;
